import axios from "axios";
import { wasUrl } from "utils/commonUtil";
import { apiWithAuth } from "./apiHelpers";

export const getDealCount = async (memberId, authToken) => {
    const response = await axios.get(`${wasUrl}trade/request/count/${memberId}`,
        {
            headers: {
                'X-Login-Token': `Bearer ${authToken}`
            }
        }
    );
    return response;
}


export const newCountDeal = async (authToken) => {
    const response = await axios.get(`${wasUrl}trade/request/count/new`,
        {
            headers: {
                'X-Login-Token': `Bearer ${authToken}`
            }
        }
    );
    return response;
}

export const submitDeal = async (cookieDatas, data) => {
    try {
        await apiWithAuth(
            `${wasUrl}trade/request`,
            'post',
            data,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return 200;
    } catch (error) {
        return 500;
    }
}

// export const getDetailInfo = async (data, tradeId) => {
//     const response = await axios.get(`${wasUrl}trade/member/${tradeId}`, {
//         params: data
//     });
//     return response.data;
// }

export const completeDeal = async (cookieDatas, tradeId, data) => {
    try {
        await apiWithAuth(
            `${wasUrl}trade/${tradeId}`,
            'post',
            data,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return 200;
    } catch (error) {
        return 500;
    }
}

export const getMyDealList = async (cookieDatas, type, data) => {
    try {
        const response = await apiWithAuth(
            `${wasUrl}trade/my/${type === '진행 중' ? 'processing' : 'complete'}`,
            'get',
            data,
            cookieDatas.authToken,
            cookieDatas.refreshToken,
            cookieDatas.setCookie
        );
        return response.data;
    } catch (e) {
        console.error(e);
    }
}