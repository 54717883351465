import backBtnImage from "../../assets/icon/backButton.svg";
import React from "react";
import {closePopup} from "../../utils/commonUtil";

const SignTerms2 = () => {
  const closeEvent = () => {
    closePopup("terms2");
  }

  return (
    <>
      <section id="terms2" className="container termsContainer bc-lightGray10 d-none">
        <div className="backHeader pt-10 pb-10 bc-white">
          <div className="inner d-flex a-center j-left">
            <button
              className="btnBox"
              onClick={closeEvent}
            >
              <img
                src={backBtnImage}
                alt="뒤로가기"
              />
            </button>
          </div>
        </div>
        <div className="termsContent fs-24 scrollbar">
          <div className="inner pb-20">
            <p className="fs-32 fw-7 mt-20 mb-20">개인정보보호정책</p>
            <p>함평군은 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립/공개합니다.</p>
            <br/>
            <p className="fw-6">제1조(개인정보의 처리목적)</p>
            <p>① 함평군은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
            <p className="ml-5 fw-6">1. 두레플랫폼 회원 가입 및 관리</p>
            <p className="ml-5">회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별/인증, 회원자격 유지/관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인, 각종 고지/통지, 고충처리 등을 목적으로 개인정보를 처리합니다. 수집정보는 휴대폰번호, 닉네임, 지역 정보입니다.</p>
            <p className="ml-5 fw-6">2. 플랫폼 서비스 제공</p>
            <p className="ml-5">플랫폼 서비스 제공, 이벤트 정보 제공, 본인인증의 목적으로 개인정보를 처리하며 수집 정보는 중복가입확인정보, 휴대폰번호, 컨텐츠 이용 내역, 사용자가 등록한 1:1질문 및 지역정보입니다.</p>
            <p className="ml-5 fw-6">3. 서비스 이용에 따른 자동 수집 정보</p>
            <p className="ml-5">접속관리, 이용자별 사용환경 제공, 이용정보 파악, 이벤트 및 프로모션 통계 확인, 맞춤형 정보 제공을 목적으로 수집하며, 수집 정보는 IP 주소, 쿠키, 방문 일시, 서비스 이용 기록, 모바일 기기 정보(PC 사양정보, 스마트 OS 탑재 모바일 기기(Tablet PC, 스마트폰 등)의 모델명, OS정보, 기기고유번호(디바이스 아이디 또는 IMEI)입니다.</p>
            <p>② 함평군이(가) 개인정보 보호법 제32조에 따라 등록/공개하는 개인정보 파일의 처리목적은 행정안전부 개인정보보호 종합지원 포털(www.privacy.go.kr) → 개인정보민원 → 개인정보열람등 요구 → 개인정보파일 목록검색 → 기관명에 “함평군” 검색 → 파일명 선택 메뉴를 활용하시기 바랍니다.</p>
            <br/>
            <p className="fw-6">제2조(개인정보의 처리 및 보유기간)</p>
            <p>① 함평군은 법령에 따른 개인정보 보유/이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유/이용기간 내에서 개인정보를 처리/보유합니다.</p>
            <p>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
            <p className="ml-5">1. 플랫폼 회원 가입 및 관리 : 공공기관 플랫폼 탈퇴시까지 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지</p>
            <p className="ml-5">1) 관계 법령 위반에 따른 수사/조사 등이 진행중인 경우에는 해당 수사/조사 종료시까지</p>
            <p className="ml-5">2) 플랫폼 이용에 따른 채권/채무관계 잔존시에는 해당 채권/채무관계 정산시까지</p>
            <p className="ml-5">2. 기타 개인정보 처리 및 보유기간은 행정안전부 개인정보 포털에서 제공하는 함평군 개인정보 파일목록 참조</p>
            <br/>
            <p className="fw-6">제3조(개인정보의 제3자 제공)</p>
            <p>① 함평군은 원칙적으로 이용자의 개인정보를 제1조 (개인정보의 처리 목적)에서 명시한 범위 내에서 처리하며, 이용자의 사전 동의 없이 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. 다만, 다음의 경우는 예외로 처리됩니다.</p>
            <p className="ml-5">- 다른 법률에 특별한 규정이 있는 경우</p>
            <p className="ml-5">- 범죄의 수사와 같이 개인정보 보호법 제 18조 ②에 해당되는 경우</p>
            <p className="ml-5">- 이용자가 사전에 제3자 제공 및 공개에 동의한 경우</p>
            <p className="ml-5">- 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우</p>
            <p className="ml-5">- 개인을 식별하기에 특정할 수 없는 상태로 가공하여 이용하는 경우</p>
            <br/>
            <p className="fw-6">제4조(개인정보처리의 위탁)</p>
            <p>① 함평군은 원칙적으로 이용자의 동의없이 해당 개인정보의 처리를 타인에게 위탁하지 않습니다. 향후 개인정보처리 위탁 필요가 생길 경우, 위탁대상자, 위탁업무내용, 위탁기간, 위탁계약 내용(개인정보보호 관련 법규의 준수, 개인정보에 관한 제3자 제공 금지 및 책임부담 등을 규정)을 공지사항 및 분야별 개인정보처리방침을 통해 고지하겠습니다. 또한 필요한 경우 사전 동의를 받도록 하겠습니다.</p>
            <p>② 함평군은 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적/관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리/감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</p>
            <p>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
            <p>④ 개인정보 처리의 위탁 사항 고지</p>
            <p className="ml-5">- 위탁업체 : (주)부일기획</p>
            <p className="ml-5">- 위탁목적 : 원활한 두레서비스 제공</p>
            <p className="ml-5">- 위탁업무 : 두레 플랫폼 유지보수</p>
            <p className="ml-5">- 취급위탁 항목 : 주소, 연락처</p>
            <br/>
            <p className="fw-6">제5조(정보주체의 권리/의무 및 행사방법)</p>
            <p>① 정보주체는 함평군에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
            <p className="ml-5">1. 재위탁 금지에 관한 사항</p>
            <p className="ml-5">2. 개인정보파일의 복사에 관한 사항</p>
            <p className="ml-5">3. 개인정보의 관리상황 검사 및 소속직원의 교육에 관한 사항</p>
            <p className="ml-5">4. 수탁기관에서 준수하여야 할 의무를 위반한 경우의 손해배상 등에 관한 사항</p>
            <p>② 제1항에 따른 권리 행사는 함평군에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 함평군는(은) 이에 대해 지체없이 조치하겠습니다.</p>
            <p>③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 함평군는(은) 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</p>
            <p>④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
            <p>⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 함평군이(가) 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.</p>
            <br/>
            <p className="fw-6">제6조(처리하는 개인정보 항목)</p>
            <p>함평군는(은) 다음의 개인정보 항목을 처리하고 있습니다. 함평군는(은) 이용자들의 개인정보를 취급함에 있어 개인정보 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.</p>
            <p className="ml-5 fw-6">1. 플랫폼 회원 가입 및 관리</p>
            <p className="ml-5">필수항목 : 아이디, 이름, 비밀번호, 지번주소, 도로명주소, 전화번호, 휴대폰번호</p>
            <p className="ml-5 fw-6">2. 민원사무 처리</p>
            <p className="ml-5">필수항목 : 성명, 전화번호, 주소</p>
            <p className="ml-5 fw-6">3. 민원사무 처리</p>
            <p className="ml-5">필수항목 : 성명, 전화번호, 주소</p>
            <p className="ml-5">4. 기타 함평군이(가) 처리하는 개인정보 항목은 행정안전부 개인정보보호 종합지원 포털(www.privacy.go.kr) → 개인정보민원 → 개인정보열람등 요구 → 개인정보파일 목록검색 → 기관명에 “함평군” 검색 → 파일명 선택 메뉴를 활용하시기 바랍니다.</p>
            <br/>
            <p className="fw-6">제7조(개인정보의 파기)</p>
            <p>① 함평군는(은) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
            <p>② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보(또는 개인정보파일)을 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p>
            <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
            <p className="ml-5 fw-6">1. 파기절차</p>
            <p className="ml-5">함평군는(은) 파기하여야 하는 개인정보(또는 개인정보파일)에 대해 개인정보 파기계획을 수립하여 파기합니다. 함평군는(은) 파기 사유가 발생한 개인정보(또는 개인정보파일)을 선정하고, 함평군는(은) 개인정보 보호책임자의 승인을 받아 개인정보(또는 개인정보파일)을 파기합니다.</p>
            <p className="ml-5 fw-6">2. 파기방법</p>
            <p className="ml-5">함평군는(은) 전자적 파일 형태로 기록/저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록/저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</p>
            <br/>
            <p className="fw-6">제8조(개인정보의 안전성 확보조치)</p>
            <p>① 함평군는(은) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
            <p className="ml-5">1. 관리적 조치 : 내부관리계획 수립/시행, 정기적 직원 교육 등</p>
            <p className="ml-5">2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</p>
            <p className="ml-5">3. 물리적 조치: 전산실, 자료보관실 등의 접근통제</p>
            <br/>
            <p className="fw-6">제9조(개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)</p>
            <p>① 함평군는(은) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
            <p>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p>
            <p className="ml-5">1. 쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</p>
            <p className="ml-5">2. 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</p>
            <p className="ml-5">3. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>
            <p>③ 회사는 서비스 개선을 위한 이용자 행태를 분석하고 이용자 대상 맞춤형 광고 서비스 전송의 목적으로 SDK등을 설치·운영 하고 있습니다. 이 과정에서 이용자의 행태정보와 광고ID가 자동으로 수집·이용될 수 있으며, 개인식별정보와는 결합되어 이용되지 않습니다.</p>
            <p className="ml-5">1. 행태정보란 웹사이트 방문 이력, 앱 사용 이력, 구매 및 검색 이력 등 이용자의 관심, 흥미, 기호 및 성향 등을 파악하고 분석할 수 있는 온라인상의 이용자 활동정보를 말합니다. 광고ID는 운영체제가 기기에 부여하는 광고 식별 값(ADID, IDFA 등)을 말합니다.</p>
            <p className="ml-5">2. 이용자는 웹브라우저나 스마트폰 설정 기능을 이용하여 언제든지 이를 거부할 수 있습니다.</p>
            <p className="ml-5">- 웹브라우저 : 우측 상단 > 인터넷 옵션(설정) > 개인정보 메뉴 > 쿠키 > 차단</p>
            <p className="ml-5">- Android : 설정 > Google > 광고 > ‘광고 맞춤 설정’ 선택 해제</p>
            <p className="ml-5">- iOS : 설정 > 개인 정보 보호 > 추적 > ‘두레’ 비활성화</p>
            <br/>
            <p className="fw-6">제10조(개인정보 처리책임자)</p>
            <p>① 함평군는(은) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 처리책임자를 지정하고 있습니다.</p>
            <p className="ml-5">- 개인정보 보호책임자 : 함평군</p>
            <p className="ml-5">- 함평군 개인정보 처리 담당 : 함평군농업기술센터 임대사업담당자</p>
            <p className="ml-5">- 연락처 : 061-320-2502</p>
            <p>② 정보주체께서는 함평군의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 함평군는(은) 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.</p>
            <br/>
            <p className="fw-6">제11조(개인정보 처리책임자)</p>
            <p>① 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 함평군는(은) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
            <p>② 정보주체께서는 제1항의 열람청구 접수/처리부서 이외에, 행정안전부의 ‘개인정보보호 종합지원 포털’ 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람청구를 하실 수 있습니다.</p>
            <p>행정안전부 개인정보보호 종합지원 포털 → 개인정보 민원 → 개인정보 열람등 요구 (공공아이핀을 통한 실명인증 필요)</p>
            <br/>
            <p className="fw-6">제12조(권익침해 구제방법)</p>
            <p>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</p>
            <p>&lt;아래의 기관은 함평군과(와)는 별개의 기관으로서, 함평군의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다&gt;</p>
            <p>○ 개인정보보호 종합지원 포털 (행정안전부 운영)</p>
            <p className="ml-5">- 소관업무 : 개인정보 침해사실 신고, 상담 신청, 자료제공</p>
            <p className="ml-5">- 홈페이지 : www.privacy.go.kr</p>
            <p className="ml-5">- 전화 : 02-2100-3394</p>
            <p>○ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
            <p className="ml-5">- 소관업무 : 개인정보 침해사실 신고, 상담 신청</p>
            <p className="ml-5">- 홈페이지 : privacy.kisa.or.kr</p>
            <p className="ml-5">- 전화 : (국번없이) 118</p>
            <p className="ml-5">- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</p>
            <p>○ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</p>
            <p className="ml-5">- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</p>
            <p className="ml-5">- 홈페이지 : privacy.kisa.or.kr</p>
            <p className="ml-5">- 전화 : (국번없이) 118</p>
            <p className="ml-5">- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</p>
            <p>○ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
            <p className="ml-5">- 소관업무 : 개인정보 침해 관련 형사사건 문의 및 신고</p>
            <p className="ml-5">- 홈페이지 : www.netan.go.kr</p>
            <p className="ml-5">- 전화 : (사이버범죄) 02-393-9112, (경찰청 대표) 1566-0112</p>
            <p>② 정보주체께서는 함평군의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 함평군는(은) 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.</p>
            <p className="fw-6">제12조(개인정보 처리방침 변경)</p>
            <p>① 이 개인정보 처리방침은 2024.09. 20.부터 적용됩니다</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignTerms2;