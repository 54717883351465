import React, { useEffect, useState } from "react";
import { ReactComponent as CircleAlarm } from "assets/icon/circleAlarm.svg";
import CommonPopup from "components/popup/CommonPopup";
import colorFillPhone from 'assets/icon/color-fill-phone.png';
import { useNavigate } from "react-router-dom";

const Footer = ({ hasNewTrade }) => {  // hasNewTrade prop 추가
  const navigate = useNavigate();
  const [callPopup, setCallPopup] = useState(false);
  const [notMobilePopup, setNotMobilePopup] = useState(false);

  useEffect(() => {
    const confirmBtn = document.querySelector('#callCenterPopup .twoBtn');
    if (confirmBtn) {
      confirmBtn.addEventListener('click', handleCall);
    }
    return () => {
      if (confirmBtn) {
        confirmBtn.removeEventListener('click', handleCall);
      }
    };
  }, []);

  const handleCall = () => {
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = "tel:070-7730-6881";
    } else {
      setCallPopup(false);
      setNotMobilePopup(true);
    }
  };

  return (
    <>
      <footer id="footer" className="footer bc-white fs-24 fw-7 fc-black7 d-flex a-center j-left">
        <button className="d-flex a-center j-center"
          onClick={() => navigate('/myTradeList')}
          style={{ borderRight: '1px solid $mediumGray18' }}
        >
          내 거래
          {hasNewTrade && <CircleAlarm className="ml-5" />} {/* 알림이 있는 경우에만 아이콘 표시 */}
        </button>
        <span className="d-block"
          style={{ borderRight: '1px solid $mediumGray18' }}></span>
        <button onClick={() => navigate('/home')}>
          홈으로
        </button>
        <span className="d-block"></span>
        <button onClick={() => setCallPopup(true)}>
          콜센터
        </button>
      </footer>

      <CommonPopup
        id='callCenterPopup'
        title1='콜센터로 '
        title2='전화'
        title3='하시겠어요?'
        text='콜센터 운영시간<br/>오전 09:00 ~ 오후 06:00'
        btnText1='닫기'
        btnText2='전화하기'
        btnType='two'
        status={callPopup}
        setStatus={setCallPopup}
        btnEvent1={false}
      />
      <CommonPopup
        id='notMobilePopup'
        image={colorFillPhone}
        title1='고객센터로 문의해주세요.'
        title2=''
        title3=''
        text='070-7730-6881로 문의해주세요. 콜센터 운영시간 : 오전 09:00 ~ 오후 06:00'
        btnText1='확인'
        status={notMobilePopup}
        setStatus={setNotMobilePopup}
        btnEvent1={false}
      />
    </>
  );
};

export default React.memo(Footer);
