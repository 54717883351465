import React, { useEffect, useState } from "react";
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import BackHeader from "components/header/BackHeader";
import CommonInputBox from "components/CommonInputBox";
import CommonBarBtn from "components/button/CommonBarBtn";
import CommonPopup from "components/popup/CommonPopup";
import rightArrow from "assets/icon/rightArrow.svg"
import { apiWithAuth } from "api/apiHelpers";  // 헬퍼 함수 import
import { useCookies } from "react-cookie";
import { checkNick } from "api/Join";
import { useNavigate } from "react-router-dom";
import { wasUrl } from "utils/commonUtil";

const MyInfoUpdate = () => {
    const [loginId, setLoginId] = useState("");
    const [name, setName] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDuplicatePopupOpen, setIsDuplicatePopupOpen] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'refreshToken']); // removeCookie 추가
    const authToken = cookies.authToken;
    const refreshToken = cookies.refreshToken;
    const navigate = useNavigate();

    useEffect(() => {
        pageLoadingEvent();
        const userId = localStorage.getItem('userId');
        if (userId) {
            getMyInfo(userId); // 회원정보 불러오기
        }

        const confirmBtn = document.querySelector('#deleteUserPopup .twoBtn');
        confirmBtn.addEventListener('click', handleDeleteAccount);
        const changeBtn = document.getElementById('changeBtn');
        changeBtn.addEventListener('click', handleUpdate);

        return () => {
            confirmBtn.removeEventListener('click', handleDeleteAccount);
            changeBtn.removeEventListener('click', handleUpdate);
        }
    }, [authToken, refreshToken, setCookie]);

    // 회원정보 가져오기
    const getMyInfo = async (userId) => {
        const url = `${wasUrl}member/${userId}`;
        try {
            const response = await apiWithAuth(url, 'get', null, authToken, refreshToken, setCookie);
            setLoginId(response.data.loginId);
            setName(response.data.name); // name 필드 사용
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    };

    // 닉네임 중복 체크
    const checkNicknameDuplicate = async (value) => {
        try {
            const data = { name: value };  // name으로 변경
            const response = await checkNick(data);
            return response.data.checkResult;
        } catch (error) {
            console.error("닉네임 중복 확인 중 오류 발생:", error);
            return false;
        }
    };

    // 회원정보 수정
    const handleUpdate = async () => {
        const getName = document.getElementById("nickname").value;
        const isDuplicate = await checkNicknameDuplicate(getName);

        if (!isDuplicate) {
            setIsDuplicatePopupOpen(true); // 중복 시 팝업 열기
        } else {
            try {
                const userId = localStorage.getItem('userId');
                const url = `${wasUrl}member/${userId}`;
                const data = { name: getName };
                const response = await apiWithAuth(url, 'post', data, authToken, refreshToken, setCookie);
                if (response.status === 200) {
                    navigate("/menuMain"); // 업데이트 후 메인 페이지로 이동
                } else {
                    console.error("Failed to update nickname");
                }
            } catch (error) {
                console.error("Error occurred while updating nickname:", error);
            }
        }
    };

    // 회원탈퇴 로직 (기존 유지)
    const handleDeleteAccount = async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await fetch(`${wasUrl}member/withdrawal/${userId}`, {
                method: "POST",
                headers: {
                    "X-Login-Token": `Bearer ${authToken}`
                },
            });

            if (response.ok) {
                localStorage.clear();
                removeCookie('authToken', { path: '/' });  // 쿠키 삭제
                removeCookie('refreshToken', { path: '/' });
                window.location.href = "/dure";
            } else {
                console.error("Failed to delete account");
            }
        } catch (error) {
            console.error("Error occurred while deleting account:", error);
        }
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <section id="MyInfoUpdate" className="container">
            <Header />
            <div className="content content6 bottom scrollbar">
                <BackHeader
                    title='내 정보'
                />
                <div className="inner mt-40 mb-30">
                    <CommonInputBox
                        title='아이디'
                        titleClass=''
                        subTitle='변경불가'
                        disabled={true}
                        value={loginId}
                        placeholder={loginId}
                    />
                </div>
                <div className="inner mb-30">
                    <CommonInputBox
                        id="nickname"
                        title='닉네임'
                        placeholder='닉네임을 입력하세요.'
                        maxLength={8}
                        type='noSpecialText'
                        value={name}
                        setValue={setName} // name 상태 업데이트
                    />
                </div>
                <div className="inner">
                    <div className="divider mb-30"></div>
                    <button className="deleteUserBtnBox d-flex a-center j-between w-100" onClick={togglePopup}>
                        <span className="fw-6 fs-24 fc-mediumGray">회원탈퇴</span>
                        <span className="fw-6 fs-24 fc-mediumGray"><img src={rightArrow} alt="arrowIcon"/></span>
                    </button>
                </div>
            </div>
            <div className="inner">
                <CommonBarBtn
                    id='changeBtn'
                    active={true}
                    text='수정하기'
                    activeText='수정하기'
                />
            </div>
            <CommonPopup
                id='deleteUserPopup'
                title1=''
                title2='회원탈퇴'
                title3='하시겠어요?'
                text='회원 탈퇴 시 모든 정보가 삭제되고 복구가 불가합니다. 정말 회원 탈퇴하시겠습니까?'
                btnText1='취소'
                btnText2='탈퇴하기'
                btnType='two'
                status={isPopupOpen}
                setStatus={setIsPopupOpen}
                btnEvent1={false}
            />
            <CommonPopup
                id='duplicatePopup'
                title1=''
                title2='닉네임'
                title3='이 중복되었어요.'
                text='이미 존재하는 닉네임입니다. 다른 닉네임을 입력해주세요.'
                btnText1='확인'
                status={isDuplicatePopupOpen}
                setStatus={setIsDuplicatePopupOpen}
                btnEvent1={false}
            />
        </section>
    );
};

export default MyInfoUpdate;
