import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pageLoadingEvent } from "utils/commonUtil";
import mailIcon from 'assets/icon/mail.svg';
import resendBtn from 'assets/image/button/resendBtn.png';
import resendBtn2 from 'assets/image/button/resendBtn2.svg';
import LoginTopBox from "../../components/login/LoginTopBox";
import LoginInputBox from "../../components/login/LoginInputBox";
import CommonBarBtn from 'components/button/CommonBarBtn';
import { sendTel, checkTel, verifyTelCode, setLogin } from 'api/LoginApi';
import CommonPopup from 'components/popup/CommonPopup';
import { useCookies } from 'react-cookie';  // 쿠키 사용
import { getMyInfoApi } from 'api/myInfoApi';

const SignUpVerNum = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(120);
  const [isActive, setIsActive] = useState(true);
  const [isPopupOpen, setIsDuplicatePopupOpen] = useState(false);
  const [isNoneVerPopupOpen, setIsNoneVerPopupOpen] = useState(false); // 인증번호 불일치 팝업 상태 관리
  const [isTimeOutPopupOpen, setIsTimeOutPopupOpen] = useState(false); // 입력 시간 초과 팝업 상태 관리
  const [verificationCode, setVerificationCode] = useState("");
  const [cookies, setCookie] = useCookies(); // 쿠키 관리 훅 추가
  const [joinToken, setJoinToken] = useState(null);

  // URL에서 전화번호 가져오기
  const queryParams = new URLSearchParams(location.search);
  const phoneNumber = queryParams.get("phone");

  // 페이지가 로드될 때 한 번만 호출
  useEffect(() => {
    pageLoadingEvent();

    if (!phoneNumber) {
      console.error("Phone number is null or undefined");
    }

    const confirmBtn = document.querySelector('#twoBtnAlreadySignPopup .twoBtn');
    confirmBtn.addEventListener('click', loginWithPhoneNumber);
    
    const nextBtn = document.getElementById('nextBtn');
    nextBtn.addEventListener('click', handleCompleteClick);

    // 엔터 또는 안드로이드 가상 키보드에서 '이동' 버튼을 눌렀을 때 이벤트 추가
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'Go') {
        handleCompleteClick();  // nextBtn의 클릭 동작을 엔터 또는 이동 키로 동일하게 호출
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      confirmBtn.removeEventListener('click', loginWithPhoneNumber);
      nextBtn.removeEventListener('click', handleCompleteClick);
      window.removeEventListener('keydown', handleKeyDown); // 추가한 리스너도 제거
    };
  }, [phoneNumber, navigate]);


  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const loginWithPhoneNumber = async () => {
    try {
      const loginData = {
        loginId: phoneNumber
      };

      // 쿠키에서 joinToken을 불러옴
      const JoinToken = cookies.joinToken;  // 쿠키에 저장된 joinToken 불러오기
      if (!JoinToken) {
        console.error('JoinToken이 설정되지 않았습니다.');
        return;
      }

      const response = await setLogin(loginData, JoinToken);  // joinToken과 함께 요청
      console.log(`responseData1:${response}`);
      if (response.status === 200) {
        console.log(`responseData2:${response}`);
        const data = response.data;
        setCookie('authToken', data.authToken, { maxAge: 60 * 60 * 24, path: "/", secure: false, sameSite: "strict" });
        setCookie('refreshToken', data.refreshToken, { maxAge: 60 * 60 * 24, path: "/", secure: false, sameSite: "strict" });
        localStorage.setItem('userId', data.userId.toString());
        const getUserName = await getMyInfoApi(response.data.userId, response.data.authToken);
        localStorage.setItem("nickName", getUserName.name);
        navigate('/home');
      } else {
        console.error('Failed to log in with the existing phone number.');
      }
    } catch (error) {
      console.log(`error:${error}`);
      console.error('Error occurred while logging in with the phone number:', error);
    }
  };



  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const resendCode = () => {
    setSeconds(120);
    document.getElementById("code").value = "";
    setVerificationCode("");
    setIsActive(true);
    sendVerificationCode(); // 재전송 버튼 클릭 시 인증 코드 전송
  };

  const sendVerificationCode = async () => {
    try {
      const data = { tel: phoneNumber }; // 전송할 데이터 객체 생성
      await sendTel(data); // sendTel 함수 호출하여 문자 전송
    } catch (error) {
      console.error("SMS 전송 중 오류 발생:", error);
    }
  };

  const checkPhoneNumber = async () => {
    try {
      const data = { tel: phoneNumber }; // 전송할 데이터 객체 생성
      const response = await checkTel(data); // checkTel 함수 호출하여 중복 확인

      if (response.status !== 200) {
        throw new Error(`Unexpected status code: ${response.status}`);
      }

      // 서버 응답에 따라 로직 처리
      if (response.data.checkResult === false) {
        setIsDuplicatePopupOpen(true);
      } else if (response.data.checkResult === true) {
        // 쿠키에 joinToken 저장 후 페이지 이동
        navigate(`/NickNameField?phone=${phoneNumber}`);
      } else {
        throw new Error("Invalid response data structure");
      }
    } catch (error) {
      console.error("전화번호 확인 중 오류 발생:", error.message);
    }
  };

  const checkVerificationCode = async () => {
    const code = document.getElementById("code").value;

    try {
      const data = { tel: phoneNumber, certNo: code };
      const response = await verifyTelCode(data);

      if (response.status === 200) {
        setCookie('joinToken', response.data.joinToken, { maxAge: 60 * 60, path: "/", secure: false, sameSite: "strict" }); // 쿠키에 joinToken 저장 (1시간 유지)

        setJoinToken(response.data.joinToken);
        return true; // 인증번호가 일치할 경우 true 반환
      } else {
        setIsNoneVerPopupOpen(true);
        return false; // 인증번호가 불일치할 경우 false 반환
      }
    } catch (error) {
      console.error("인증 코드 확인 중 오류 발생:", error);
      setIsNoneVerPopupOpen(true);
      return false; // 오류가 발생한 경우 false 반환
    }
  };

  const handleCompleteClick = async () => {
    if (seconds === 0) {
      setIsTimeOutPopupOpen(true); // 시간이 초과된 경우 팝업 열기
      setVerificationCode();
      return;
    }

    const isVerificationCodeValid = await checkVerificationCode();
    if (isVerificationCodeValid) {
      await checkPhoneNumber(); // 인증번호가 일치할 때만 중복 확인 진행
    }
  };

  return (
    <section id="SignUpVerNum" className="container">
      <div className="content content3 scrollbar">
        <div className="inner">
          <LoginTopBox
            image={mailIcon}
            text1="를"
            text2="입력해주세요"
            greenText1="인증번호 4자리"
            greenText2=""
          />
          <div className="d-flex active j-between">
            <div className="d-flex a-center j-between loginInputBox codeBox">
              <LoginInputBox
                id="code"
                maxLength={4}
                placeholder="예시) 1234"
                setValue={setVerificationCode}
                type="number"
                autoComplete={true}
              />
              <span className="timerText fc-red1 fs-24 fw-6 mr-29">{formatTime(seconds)}</span>
            </div>
            <button className="resendBtn" onClick={resendCode} disabled={isActive}>
              <img src={isActive ? resendBtn : resendBtn2} alt="재전송버튼" />
            </button>
          </div>
        </div>
      </div>
      <div className='inner'>
        <CommonBarBtn
          id='nextBtn'
          active={phoneNumber}
          text='완료'
          activeText='완료'
        />
      </div>

      <CommonPopup
        id='twoBtnAlreadySignPopup'
        btnType='two'
        title1='이미'
        title2='가입된 번호'
        title3='에요.'
        text='해당 번호로 이미 가입이 되어있습니다.로그인을 진행할까요?'
        btnText1='처음으로'
        btnText2='로그인'
        status={isPopupOpen}
        setStatus={setIsDuplicatePopupOpen}
        btnEvent1={() => navigate('/')}
        btnEvent2={() => loginWithPhoneNumber()}
      />

      <CommonPopup
        id='veriNumIncorrectPopup'
        title1=''
        title2='인증번호'
        title3='가 불일치해요.'
        text='전송된 인증문자를 확인 후 다시 입력해주세요.'
        btnText1='확인'
        status={isNoneVerPopupOpen}
        setStatus={setIsNoneVerPopupOpen}
        btnEvent1={false}
      />
      <CommonPopup
        id='timeoutPopup'
        title1=''
        title2='입력 시간'
        title3='이 초과되었어요.'
        text='인증번호 입력시간이 초과되어 새로운 인증 문자를 전송 후 입력해 주세요.'
        btnText1='확인'
        status={isTimeOutPopupOpen}
        setStatus={setIsTimeOutPopupOpen}
        btnEvent1={false}
      />
    </section>
  );
};

export default SignUpVerNum;