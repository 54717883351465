import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import {openPopup, pageLoadingEvent} from "utils/commonUtil";
import checkButton from "assets/icon/circle-checkButton.svg";
import checkButtonC from "assets/icon/circle-checkButtion-fill.svg";
import { ReactComponent as RightArrowIcon } from "assets/icon/rightArrow.svg";
import BackHeader from "components/header/BackHeader";
import LoginTopBox from "components/login/LoginTopBox";
import CommonBarBtn from "components/button/CommonBarBtn";
import SignTerms1 from "./SignTerms1";
import SignTerms2 from "./SignTerms2";


const Terms = () => {
    const [searchParams] = useSearchParams(); // URL 파라미터 가져오기
    const [isChecked, setIsChecked] = useState({
        all: false,
        terms1: false,
        terms2: false,
        terms3: false,
        terms4: false
    });

    const navigate = useNavigate();

    useEffect(() => {
        pageLoadingEvent();
    
        const termsBtn = document.getElementById('termsBtn');
        termsBtn.addEventListener('click', gotoNext);
    
        // 키보드 이벤트 리스너 추가
        const handleKeyDown = (e) => {
          if (e.key === 'Enter' || e.key === 'Go') {
            gotoNext();
          }
        };
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            termsBtn.removeEventListener('click', gotoNext);
            window.removeEventListener('keydown', handleKeyDown); // 키보드 리스너 제거
        };
    }, [isChecked]);
    

    const toggleCheck = (key) => {
        if (key === 'all') {
            const newState = !isChecked.all;
            setIsChecked({
                all: newState,
                terms1: newState,
                terms2: newState,
                terms3: newState,
                terms4: newState
            });
        } else {
            setIsChecked(prevState => {
                const updatedState = {
                    ...prevState,
                    [key]: !prevState[key],
                };
                const allChecked = updatedState.terms1 && updatedState.terms2 && updatedState.terms3 && updatedState.terms4;
                return {
                    ...updatedState,
                    all: allChecked,
                };
            });
        }
    };


    const gotoNext = () => {
        // 조건에 따라 url을 결정
        const ver = searchParams.get("ver");
        const phone = searchParams.get("phone");
        const nextUrl = ver && phone ? `/NicknameField?phone=${phone}` : "/SignUpNum";
        if (isChecked.terms1 && isChecked.terms2) {
            navigate(nextUrl);
        }
    };

    return (
        <>
            <section id="terms" className="terms container">
                <SignTerms1 />
                <SignTerms2 />
                <div className="content content3 scrollbar">
                    <BackHeader />
                    <div className="inner mt-30">
                        <LoginTopBox
                          text1="두레 이용을 위해"
                          text2="동의해주세요"
                          greenText1=""
                          greenText2="이용약관에 "
                        />
                    </div>

                    <div className="inner">
                        <div className="termsBox bc-lightGray7 br-7 d-flex a-center j-between mb-20">
                            <div
                              className="d-flex a-center j-left fs-26"
                              onClick={() => toggleCheck("all")}
                            >
                                <img
                                  className="mr-10"
                                  src={isChecked.all ? checkButtonC : checkButton}
                                  alt="체크버튼"
                                />
                                <p className="fw-6 fc-black2">약관 전체동의</p>
                            </div>
                        </div>
                    </div>

                    <div className="inner">
                        <div className="termsBox br-7 d-flex a-center j-between mb-20">
                            <div
                              className="d-flex a-center j-left fs-26"
                              onClick={() => toggleCheck("terms1")}
                            >
                                <img
                                  className="mr-10"
                                  src={isChecked.terms1 ? checkButtonC : checkButton}
                                  alt="체크버튼"
                                />
                                <p className="fw-6 fc-black2">(필수)서비스 이용약관</p>
                            </div>
                            <RightArrowIcon
                              className="arrowBtn"
                              onClick={() => openPopup("terms1")}
                              style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>

                    <div className="inner">
                        <div className="termsBox br-7 d-flex a-center j-between mb-20">
                            <div
                              className="d-flex a-center j-left fs-26"
                              onClick={() => toggleCheck("terms2")}
                            >
                                <img
                                  className="mr-10"
                                  src={isChecked.terms2 ? checkButtonC : checkButton}
                                  alt="체크버튼"
                                />
                                <p className="fw-6 fc-black2">(필수)개인정보처리방침</p>
                            </div>
                            <RightArrowIcon
                              className="arrowBtn"
                              onClick={() => openPopup("terms2")}
                              style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                    {/* 위치정보 서비스 동의 보류 */}
                    {/* <div className="inner">
                    <div className="termsBox br-7 d-flex a-center j-between">
                        <div
                            className="d-flex a-center j-left fs-26"
                            onClick={() => toggleCheck("terms3")}
                        >
                            <img
                                className="mr-10"
                                src={isChecked.terms3 ? checkButtonC : checkButton}
                                alt="체크버튼"
                            />
                            <p className="fw-6 fc-black2">(선택)위치정보 서비스 이용약관</p>
                        </div>
                        <RightArrowIcon className="arrowBtn" style={{ cursor: "pointer" }} />
                    </div>
                </div> */}
                </div>
                <div className="inner">
                    <CommonBarBtn
                      id='termsBtn'
                      text='다음'
                      activeText='다음'
                      active={isChecked.terms1 && isChecked.terms2}
                    />
                </div>
            </section>
        </>
    );
}

export default Terms;
