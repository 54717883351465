import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCategoryNameById } from "utils/formatUtil";
import noImage from "assets/image/trade/noSmallImage.svg";
import RequestOffer from "assets/image/button/requestOffer.png";
import RequestProvide from "assets/image/button/requestProvide.png";
import { pageLoadingEvent, searchBold } from "../../utils/commonUtil";

const TradeImageBox = ({ tradeType, region, image, status }) => {
  return (
    <div
      className="tradeImgBox p-relative"
    >
      <div
        className="imgTextAbs d-flex p-absolute a-center j-center"
      >
        <img
          src={tradeType === 1 ? RequestProvide : RequestOffer}
          alt={tradeType === 1 ? "해주세요" : "해드립니다"}
          style={{ zIndex: 10 }}
        />
        <span
          className='region d-flex a-center j-center bc-red5 fs-18 fw-6 fc-white ls-4'
          style={{ zIndex: 10 }}
        >
          {region || "정보 없음"}
        </span>
      </div>
      <img
        src={image}
        alt="thumbnail"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      {status === 3 &&
        <div
          className="completeDealCover d-flex a-center j-center p-absolute"
          style={{ width: '100%', height: '100%', top: 0, left: 0 }}
        >
          <div
            className="coverBackground p-absolute"
            style={{ width: '100%', height: '100%', opacity: 0.6, background: '#000000', top: 0, left: 0, zIndex: 5 }}
          >
          </div>
          <span className="fs-26 fw-6 fc-white d-flex a-center j-center p-absolute"
            style={{ zIndex: 10 }}>
            {"거래 완료"}
          </span>
        </div>
      }
    </div >
  );
};

const formatPrice = (price, costNegotiate) => {
  if (costNegotiate) {
    return <p className="fc-green8">가격 협의</p>;
  } else if (price === 0 || isNaN(price)) {
    return <p className='price fc-red6'>무료</p>;
  } else {
    const formattedPrice = Number(price).toLocaleString(); // 숫자를 천단위 콤마로 포맷
    return <p className='price fc-green8'>{formattedPrice}원</p>;
  }
};


const TradeBox = (
  {
    id, tradeType, title,
    categoryId, nickname, status,
    dealCount, region, imagePath,
    price, costNegotiate, completeActive,
    dealCompleteDate, searchKeyword
  }
) => {
  const navigate = useNavigate();

  const handleBlockClick = () => {
    navigate(`/tradeDetailMain?tradeId=${id}`);  // navigate를 사용하여 id 값을 전달
  };

  const boxResize = () => {
    let box = document.querySelectorAll(".tradeBox");

    box.forEach((element) => {
      let width = element.offsetWidth;
      let height = width * 0.4;
      element.style.height = `${height}px`;
    });
  }

  // 화면이 리사이즈될 때마다 boxResize 함수 실행
  window.addEventListener('resize', boxResize);

  useEffect(() => {
    pageLoadingEvent();
    window.addEventListener('popstate', boxResize);
    // 페이지 로드 시 한번 실행
    // boxResize();
    return () => {
      window.removeEventListener('popstate', boxResize);
    };
  }, []);

  // 검색어 강조
  let textValue = searchKeyword ? searchBold(title, searchKeyword) : title

  return (
    <button
      className='tradeBox w-100 d-flex a-center j-center'
      onClick={handleBlockClick}
    >
      <TradeImageBox
        tradeType={tradeType}
        region={region}
        image={imagePath || noImage}
        status={status}
      />
      <div className="tradeText bc-white">
        <div className="topBox d-flex a-center j-between fs-18 fc-black6 fw-6">
          {categoryId &&
            <p className="category circle bc-lightGray10">
              {getCategoryNameById(categoryId) || "정보 없음"}
            </p>
          }
          {nickname &&
            <p className="nickname">{nickname}</p>
          }
          {status !== 3 && dealCount &&
            <p className="dealCount">
              신청자 <span className="fs-20 fc-green1 fw-7">{dealCount}</span>명
            </p>
          }
          {status === 3 &&
            <p className="complete circle bc-gray1 fc-white">
              완료
            </p>
          }
        </div>

        <div className="titleBox">
          <p
            className="title ta-left fs-26 fw-6 ellipsis-2 fc-black2"
            dangerouslySetInnerHTML={{ __html: textValue }}
          >

          </p>
        </div>

        <div className="bottomBox d-flex a-center j-left fs-26 fw-7">
          {status !== 3 && String(price) &&
            formatPrice(price, costNegotiate)
          }
          {completeActive &&
            /* 거래 완료 이벤트 추가 필요 */
            <button className="completeBtn w-100 fs-20 fw-6 bc-green1 fc-white br-6">
              거래 완료
            </button>
          }
          {dealCompleteDate &&
            <p className="fs-20">{dealCompleteDate} 거래완료</p>
          }
        </div>
      </div>
    </button>
  );
};

export default React.memo(TradeBox);