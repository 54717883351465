import axios from "axios";
import { wasUrl } from "utils/commonUtil";

export const setJoin = async (data, joinToken) => {
    const response = await axios.post(`${wasUrl}join/member`, data,
        {
            headers: {
                'X-Login-Token': `Bearer ${joinToken}`
            }

        }
    );
    return response;
}

export const checkNick = async (data)=> {
  const response = await axios.post(`${wasUrl}join/check/name`,data);
  return response;
}