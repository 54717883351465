import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { pageLoadingEvent } from "utils/commonUtil";
import smile from "assets/image/smile.svg";
import LoginTopBox from "components/login/LoginTopBox";
import LoginInputBox from "components/login/LoginInputBox";
import CommonBarBtn from "components/button/CommonBarBtn";
import { checkNick, setJoin } from "api/Join";
import CommonPopup from "components/popup/CommonPopup";
import { useCookies } from "react-cookie";
import {getMyInfoApi} from "../../api/myInfoApi";  // 쿠키 사용

const NickNameField = () => {
  const [nickname, setNickname] = useState("");
  const [isDuplicatePopupOpen, setIsDuplicatePopupOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phoneNumber = queryParams.get("phone"); // 전화번호 가져오기
  const [cookies] = useCookies(['joinToken']);  // 쿠키에서 userJoinToken 가져오기
  const joinToken = cookies.joinToken;  // 쿠키에서 userJoinToken 읽기
  const navigate = useNavigate();
  const [, setCookie] = useCookies();  // 쿠키 설정용 함수 추가

  useEffect(() => {
    pageLoadingEvent();

    if (!phoneNumber || !joinToken) {
      console.error("Phone number or userJoinToken is null or undefined");
    } else {
    }

    const duplicateBtn = document.querySelector('#nickNameDuplicatePopup .oneBtn');
    duplicateBtn.addEventListener('click', () => setIsDuplicatePopupOpen(false));

    const nickNameBtn = document.getElementById('nickNameBtn');
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'Go') {
        checkNicknameDuplicate();  // 닉네임 중복 검사 함수 호출
      }
    };

    // 클릭 및 키보드 이벤트 리스너 추가
    nickNameBtn.addEventListener('click', checkNicknameDuplicate);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      duplicateBtn.removeEventListener('click', () => setIsDuplicatePopupOpen(false));
      nickNameBtn.removeEventListener('click', checkNicknameDuplicate);
      window.removeEventListener('keydown', handleKeyDown); // 키다운 이벤트 리스너 제거
    };
  }, [phoneNumber, joinToken]);


  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const checkNicknameDuplicate = async () => {
    const nick = document.getElementById("nickname").value;
    try {
      const data = { name: nick };
      const response = await checkNick(data);
      if (response.data.checkResult === true) {
        await setNick(); // 닉네임 중복이 없는 경우에만 setNick 함수 호출
      } else if (response.data.checkResult === false) {
        setIsDuplicatePopupOpen(true);
      }
    } catch (error) {
      console.error("닉네임 중복 확인 중 오류 발생:", error);
      return false; // 중복 확인 실패 시, 안전을 위해 중복으로 처리
    }
  };

  const setNick = async () => {
    const nick = document.getElementById("nickname").value;
    const data = {
      loginId: phoneNumber,
      name: nick,
      doAgreeLocationInfoSend: true,
    };

    try {
      const response = await setJoin(data, joinToken);  // 쿠키에서 가져온 userJoinToken 사용

      // authToken: 3시간 유지, refreshToken: 30일 유지
      const options = { path: "/", secure: false, sameSite: "strict" };
      setCookie("authToken", response.data.authToken, { ...options, maxAge: 3 * 60 * 60 }); // 3시간 유지
      setCookie("refreshToken", response.data.refreshToken, { ...options, maxAge: 30 * 24 * 60 * 60 }); // 30일 유지

      localStorage.setItem('userId', response.data.userId);
      const getUserName = await getMyInfoApi(response.data.userId, response.data.authToken);
      localStorage.setItem("nickName", getUserName.name);

      navigate("/home?registered=true");
    } catch (error) {
      console.error("회원가입 API 호출 중 에러 발생:", error);
    }
  };

  // 완료 버튼 누르면 checkNicknameDuplicate 만 실행되면 됨
  return (
    <section id="NickNameField" className="container">
      <div className='content content3 scrollbar'>
        <div className="inner">
          <LoginTopBox
            image={smile}
            text1='두레에서 사용될'
            text2='을 입력해주세요.'
            greenText1=''
            greenText2='닉네임'
          />
          <div className="d-flex a-center j-center loginInputBox">
            <LoginInputBox
              id='nickname'
              type='noSpecialText'
              placeholder='예시) 두레공주'
              setValue={setNickname}
              maxLength={8}
            />
          </div>
        </div>
      </div>
      <div className="inner">
        <CommonBarBtn
          id='nickNameBtn'
          text='완료'
          activeText='완료'
          active={nickname}
        />
      </div>
      <CommonPopup
        id='nickNameDuplicatePopup'
        title1=''
        title2='닉네임'
        title3='이 중복되었어요.'
        text='이미 존재하는 닉네임입니다. 다른 닉네임을 입력해주세요.'
        btnText1='확인'
        status={isDuplicatePopupOpen}
        setStatus={setIsDuplicatePopupOpen}
        btnEvent1={false}
      />
    </section>
  );
};

export default NickNameField;
