import axios from "axios";
import {wasUrl} from "utils/commonUtil";


// 내정보 가져오기
export const getMyInfoApi = async (userId,authToken) => {
  const response = await axios.get(`${wasUrl}member/${userId}`,
    {
        headers : {
          'X-Login-Token': `Bearer ${authToken}`
        }
    }
  );

  return response.data;
}

// 로그이