import axios from "axios";
import {wasUrl} from "../utils/commonUtil";

// 사업장 조회
export const getRentSiteApi = async (token) => {
  const response = await axios.get(`${wasUrl}rent/site/member`, {
    headers : {
      'X-Login-Token': `Bearer ${token}`
    }
  });

  return response.data;
};

// 카테고리 조회
export const getRentCategoryApi = async (token, data) => {
  const response = await axios.get(`${wasUrl}rent/machine/category/member`, {
    params: data,
    headers : {
      'X-Login-Token': `Bearer ${token}`
    }
  });

  return response.data;
}

// 장비 조회
export const getRentMachineApi = async (token, data) => {
  const response = await axios.get(`${wasUrl}rent/machine/member`, {
    params: data,
    headers : {
      'X-Login-Token': `Bearer ${token}`
    }
  });

  return response.data;
}

// 장비 상세 조회
export const getRentMachineDetailApi = async (token, data) => {
  const response = await axios.get(`${wasUrl}rent/machine/${data}/member`, {
    headers : {
      'X-Login-Token': `Bearer ${token}`
    }
  });

  return response.data;
}

// 임대 신청
export const postRentApi = async (data, token) => {
  await axios.post(`${wasUrl}rent`, data, {
    headers : {
      'X-Login-Token': `Bearer ${token}`
    }
  });
};

// 회원여부 확인
export const getCheckApi = async (token) => {
  const response = await axios.get(`${wasUrl}rent/check`, {
    headers : {
      'X-Login-Token': `Bearer ${token}`
    }
  });

  return response.data.joined;
};

// 임대리스트 조회
export const getRentListApi = async (token, data) => {
  const response = await axios.get(`${wasUrl}rent/log/member`, {
    params: data,
    headers : {
      'X-Login-Token': `Bearer ${token}`
    }
  });

  return response.data;
};